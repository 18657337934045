
import { defineComponent } from 'vue';
import Datatable from "@/components/kt-datatable/KTDatatableLeft.vue";
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import TraineeApproveModal from '@/layout/header/partials/trainee/TraineeApproveDrawer.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'pending-trainee-list',
  components: {
    TraineeApproveModal,
    Datatable,
  },
  data() {
    return {
      actionApprove: false,
      actionEdit: false,
      actionReject: false,
      traineeInfos: [],
      batch: {
        tranche: '',
        entity_id: '',
        training_institute_id: '',
        course_id: '',
      },
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '170px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: true,
        },
        {
          name: 'Name',
          key: 'name',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Mobile',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
        },
        {
          name: 'Birth Date',
          key: 'birth_date',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Present District',
          key: 'present_district',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Present Upazila',
          key: 'present_upazila',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Training Institute',
          key: 'institute',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
          width: '200px',
        },
      ],
      api_url: '',
      tranche: [],
      institutes: [],
      course: [],
      loading: true,
      association: [],
      tableData: [],
      componentKey: 0,
    };
  },
  async created() {
    // await this.actioncheck();
    await this.getAssociation();
    await this.getTranche();
    await this.getPendingTrainee();
    this.api_url = this.VUE_APP_API_URL;
    Object.assign(this.tableData, this.traineeInfos);
    this.emitter.on('trainee-infos-updated', async () => {
      await this.getPendingTrainee();
      this.tableData = this.traineeInfos;
    });
  },

  methods: {
    async getPendingTrainee() {
      let entity_id = '';
      let training_institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
        this.batch.entity_id = entity_id;
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        training_institute_id = VueCookieNext.getCookie('_institute_info_id');
        entity_id = VueCookieNext.getCookie('_entity_id');
        this.batch.training_institute_id = training_institute_id;
        this.batch.entity_id = entity_id;
      }
      await ApiService.get(
        'trainee/list?active_status=0&entity_id=' +
          this.batch.entity_id +
          '&training_institute_id=' +
          this.batch.training_institute_id
      )
        .then((response) => {
          this.loading = false;
          this.traineeInfos = response.data.data;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    approve(data) {
      this.emitter.emit('trainee-approve-modal-data', data);
    },
    async courseSelect() {
      await ApiService.get(
        'course/list?tranche=' +
          this.batch.tranche +
          '&entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          this.batch.training_institute_id
      )
        .then((response) => {
          this.course = response.data.data;
          console.log(this.course);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Deleted!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('trainee/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('trainee-infos-updated', true);
              Swal.fire('Delete!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async trainingInstitute() {
      await ApiService.get('institute/list?entity_id=' + this.batch.entity_id)
        .then((response) => {
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].heading == 'Trainee') {
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Reject') {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
  },
  setup() {},
});
